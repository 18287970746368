<template>
    <div class="az-header">
        <div class="container">
            <div class="az-header-left">
                <router-link :to="'/'" class="az-logo">
                    <img alt="Borlaug Web Services" src="../../assets/logo-orange-full-sm.png">
                </router-link>
                <a class="az-header-menu-icon d-lg-none" href="" id="azMenuShow">
                    <span></span>
                </a>
            </div>
            <div class="az-header-menu">
                <div class="az-header-menu-header">
                    <router-link :to="'/'" class="az-logo">
                        <img alt="..." height="30px" src="../../assets/logo-orange.png" width="30px">
                    </router-link>
                    <a class="close" href="">&times;</a>
                </div>
                <ul class="nav">
                    <li class="nav-item">
                        <a class="nav-link with-sub" href="">
                            <i class="typcn typcn-chart-area-outline"></i> Dashboard
                        </a>
                        <div class="az-menu-sub">
                            <nav class="nav">
                                <a class="nav-link" href="javascript:void(0);">Management Console</a>
                                <a class="nav-link" href="javascript:void(0);">Billing Dashboard</a>
                            </nav>
                        </div>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link with-sub" href="">
                            <i class="typcn typcn-book"/> Services
                        </a>
                        <div class="az-menu-sub az-menu-sub-mega">
                            <div class="container">
                                <div>
                                    <nav class="nav">
                                        <span>Identifier</span>
                                        <router-link v-if="currentUser.identity" :to="{ name: 'identities-home'}" class="nav-link">Decentralized Identifier</router-link>
                                        <router-link v-if="currentUser.asset_registry" :to="{ name: 'assets-home'}" class="nav-link">Assets</router-link>
                                        <a class="nav-link" href="javascript:void(0);">KYC Compliance</a>
                                        <a class="nav-link" href="javascript:void(0);">Asset Tracking</a>
                                        <a v-if="currentUser.audits" class="nav-link" href="javascript:void(0);">Audits</a>
                                        <router-link v-if="currentUser.provenance" :to="{ name: 'provenances-home'}" class="nav-link">Provenance</router-link>
                                    </nav>
                                    <nav class="nav">
                                        <a class="nav-link" href="javascript:void(0);">Land Registry</a>
                                        <a class="nav-link" href="javascript:void(0);">Leasing</a>
                                    </nav>
                                </div>
                                <div>
                                    <nav class="nav">
                                        <span>Management &amp; Governance</span>
                                        <a class="nav-link" href="javascript:void(0);">Cooperatives</a>
                                        <a class="nav-link" href="javascript:void(0);">Collective Bargaining Agreements (CBAs)</a>
                                        <a class="nav-link" href="javascript:void(0);">Governance</a>
                                        <router-link v-if="currentUser.admin" class="nav-link" :to="{name: 'admin-home'}">Admin</router-link>
                                    </nav>
                                </div>
                                <div>
                                    <nav class="nav">
                                        <span>Small Business</span>
                                        <a class="nav-link" href="javascript:void(0);">Marketplace</a>
                                        <a class="nav-link" href="javascript:void(0);">Accounting</a>
                                        <a class="nav-link" href="javascript:void(0);">Contract Book</a>
                                    </nav>
                                </div>
                                <div>
                                    <nav class="nav">
                                        <span>Products</span>
                                        <a class="nav-link" href="javascript:void(0);">AcreCX</a>
                                        <a class="nav-link" href="javascript:void(0);">TruVito</a>

                                        <span>Blockchain</span>
                                        <a class="nav-link" href="javascript:void(0);">Borlaug Explorer</a>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item d-lg-none bd-t show" v-if="module === 'Identity'">
                        <a class="nav-link with-sub mt-2" href="">
                            <i class="typcn typcn-book"/> Decentralized Identifier
                        </a>
                        <div class="az-menu-sub az-menu-sub-mega">
                            <div class="container">
                                <div>
                                    <nav class="nav">
                                        <router-link :to="{ name: 'identities-home'}" class="nav-link">About</router-link>
                                        <router-link :to="{ name: 'identities-catalogs'}" class="nav-link">Catalogs</router-link>
                                        <router-link :to="{ name: 'claim-requests'}" class="nav-link">Claim Requests
                                            <span class="notification notification-red" v-if="outstandingClaims>0">{{ outstandingClaims }}</span>
                                        </router-link>
                                        <router-link :to="{ name: 'attestation-requests'}" class="nav-link">Attestation Requests
                                            <span class="notification notification-red" v-if="outstandingAttestations>0">{{ outstandingAttestations }}</span>
                                        </router-link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item d-lg-none bd-t show" v-if="module === 'AssetRegistry'">
                        <a class="nav-link with-sub mt-2" href="">
                            <i class="typcn typcn-book"/> Asset Registry
                        </a>
                        <div class="az-menu-sub az-menu-sub-mega">
                            <div class="container">
                                <div>
                                    <nav class="nav">
                                        <router-link :to="{ name: 'assets-home'}" class="nav-link" exact>Home</router-link>
                                        <router-link :to="{ name: 'assets-catalogs'}" class="nav-link" exact>Catalogs</router-link>
                                        <router-link :to="{ name: 'assets'}" class="nav-link" exact v-if="$route.name === 'assets'">Assets</router-link>
                                        <span class="nav-link cursor-not-allowed" v-else>Assets</span>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item d-lg-none bd-t show" v-if="module === 'Provenance'">
                        <a class="nav-link with-sub mt-2" href="">
                            <i class="typcn typcn-book"/> Provenance
                        </a>
                        <div class="az-menu-sub az-menu-sub-mega">
                            <div class="container">
                                <div>
                                    <nav class="nav">
                                        <router-link :to="{ name: 'provenances-home'}" class="nav-link" exact>About
                                        </router-link>
                                        <router-link :to="{ name: 'provenances-registries'}" class="nav-link">Process Registries
                                        </router-link>
                                        <router-link :to="{ name: 'available-processes'}" class="nav-link">Start New Process
                                            <span class="notification notification-blue" v-if="availableProcessCount>0">{{availableProcessCount}}</span>
                                        </router-link>
                                        <router-link :to="{ name: 'ongoing-process'}" class="nav-link">Ongoing Processes
                                            <span class="notification notification-blue" v-if="ongoingProcessCount>0">{{ongoingProcessCount}}</span>
                                        </router-link>
                                        <router-link :to="{ name: 'complete-process'}" class="nav-link">Completed Processes
                                            <span class="notification notification-blue" v-if="completeProcessCount>0">{{completeProcessCount}}</span>
                                        </router-link>
                                        <router-link :to="{ name: 'pending-process'}" class="nav-link">Awaiting Attestation
                                            <span class="notification notification-red" v-if="pendingStepCount>0">{{pendingStepCount}}</span>
                                        </router-link>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li class="nav-item" v-if="false">
                        <a class="nav-link with-sub" href=""><i class="typcn typcn-tabs-outline"/> My Account</a>
                        <nav class="az-menu-sub">
                            <a class="nav-link" href="javascript:void(0);">Password</a>
                            <a class="nav-link" href="javascript:void(0);">Invoices</a>
                        </nav>
                    </li>
                </ul>
            </div>
            <div class="az-header-right">
                <a class="az-header-search-link" href="">
                    <i class="fas fa-search"/>
                </a>
                <div class="az-header-message">
                    <a href="javascript:void(0);">
                        <i class="typcn typcn-messages"/>
                    </a>
                </div>
                <div v-if="false" class="dropdown az-header-notification">
                    <a class="new" href=""><i class="typcn typcn-bell"></i></a>
                    <div class="dropdown-menu">
                        <div class="az-dropdown-header mg-b-20 d-sm-none">
                            <a class="az-header-arrow" href=""><i class="icon ion-md-arrow-back"></i></a>
                        </div>
                        <h6 class="az-notification-title">Notifications</h6>
                        <p class="az-notification-text">You have 2 unread notification</p>
                        <div class="az-notification-list">
                            <div class="media new">
                                <div class="az-img-user">
                                    <img alt="" src="https://via.placeholder.com/500">
                                </div>
                                <div class="media-body">
                                    <p>Congratulate <strong>Socrates Itumay</strong> for work anniversaries</p>
                                    <span>Mar 15 12:32pm</span>
                                </div><!-- media-body -->
                            </div><!-- media -->
                            <div class="media new">
                                <div class="az-img-user online">
                                    <img alt="" src="https://via.placeholder.com/500">
                                </div>
                                <div class="media-body">
                                    <p><strong>Joyce Chua</strong> just created a new blog post</p>
                                    <span>Mar 13 04:16am</span>
                                </div><!-- media-body -->
                            </div><!-- media -->
                            <div class="media">
                                <div class="az-img-user">
                                    <img alt="" src="https://via.placeholder.com/500">
                                </div>
                                <div class="media-body">
                                    <p><strong>Althea Cabardo</strong> just created a new blog post</p>
                                    <span>Mar 13 02:56am</span>
                                </div><!-- media-body -->
                            </div><!-- media -->
                            <div class="media">
                                <div class="az-img-user">
                                    <img alt="" src="https://via.placeholder.com/500">
                                </div>
                                <div class="media-body">
                                    <p><strong>Adrian Monino</strong> added new comment on your photo</p>
                                    <span>Mar 12 10:40pm</span>
                                </div><!-- media-body -->
                            </div><!-- media -->
                        </div>
                        <div class="dropdown-footer">
                            <a href="">View All Notifications</a>
                        </div>
                    </div>
                </div>
                <div class="dropdown az-profile-menu" style="cursor: pointer;">
                    <a class="az-img-user">
                        <img alt="" :src="user_image">
                    </a>
                    <div class="dropdown-menu">
                        <div class="az-dropdown-header d-sm-none">
                            <a class="az-header-arrow" href="">
                                <i class="icon ion-md-arrow-back"/>
                            </a>
                        </div>
                        <div class="az-header-profile">
                            <div class="az-img-user">
                                <img alt="" :src="user_image">
                            </div>
                            <h6>{{ currentUser.name }}</h6>
                        </div>
                        <router-link :to="{name: 'myaccount'}" class="dropdown-item">
                            <i class="typcn typcn-user-outline"/> My Profile
                        </router-link>
                        <router-link :to="{name: 'myorg'}" class="dropdown-item">
                            <i class="typcn typcn-home-outline"/> My Organization
                        </router-link>
                        <a class="dropdown-item" href="">
                            <i class="typcn typcn-time"/> Activity Logs
                        </a>
                        <a class="dropdown-item" href="">
                            <i class="typcn typcn-cog-outline"/> Account Settings
                        </a>
                        <a @click="logout" class="dropdown-item" style="cursor: pointer;">
                            <i class="typcn typcn-power-outline"/> Log Out
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {mapGetters} from "vuex";
import EventBus from "@/event-bus";

export default {
    name: "WorkspaceHeader",
    computed: {
        ...mapGetters(["currentUser", "currentOrg"])
    },
    data() {
        return {
            //   org_services: [],
            //   identity: false,
            //   assetregistry: false,
            //   audits: false,
            //   marketplace: false,
            //   coop: false,
            //   admin: false,
            user_image: localStorage.getItem("user_image") ? localStorage.getItem("user_image") : localStorage.getItem("org_image") ? localStorage.getItem("org_image") : 'https://via.placeholder.com/500',
            outstandingClaims: 0,
            outstandingAttestations: 0,
            availableProcessCount: 0,
            ongoingProcessCount: 0,
            completeProcessCount: 0,
            pendingStepCount: 0,
            module: null
        };
    },
    watch: {
        "$route": function() {
            this.setModule();
        }
    },
    mounted() {
        this.$root.$on('refreshService', () => {
            this.init();
        });
        this.setModule();
        this.init();
        EventBus.$on('outstandingClaims', (e) => {
            this.outstandingClaims = e;
        });
        EventBus.$on('outstandingAttestations', (e) => {
            this.outstandingAttestations = e;
        });
        EventBus.$on('availableProcessCount', (e) => {
            this.availableProcessCount = e;
        });
        EventBus.$on('ongoingProcessCount', (e) => {
            this.ongoingProcessCount = e;
        });
        EventBus.$on('pendingStepCount', (e) => {
            this.pendingStepCount = e;
        });
        EventBus.$on('completeProcessCount', (e) => {
            this.completeProcessCount = e;
        });
    },
    methods: {
        init() {
            $(function() {
                'use strict';
                //bootstrap conflicts bugfix
                let bootstrapEventsNotAttached = !$._data($('.az-header .dropdown > a')[0], "events");

                if(window.matchMedia('(min-width: 992px)').matches) {
                    $('.az-navbar .active').removeClass('show');
                    $('.az-header-menu .active').removeClass('show');
                }

                if(bootstrapEventsNotAttached) {
                    // Shows header dropdown while hiding others;
                    $('.az-header .dropdown > a').on('click', function(e) {
                        e.preventDefault();
                        $(this).parent().toggleClass('show');
                        $(this).parent().siblings().removeClass('show');
                    });
                }
                ;


                // Showing submenu in navbar while hiding previous open submenu
                $('.az-navbar .with-sub').on('click', function(e) {
                    e.preventDefault();
                    $(this).parent().toggleClass('show');
                    $(this).parent().siblings().removeClass('show');
                });

                // this will hide dropdown menu from open in mobile
                $('.dropdown-menu .az-header-arrow').on('click', function(e) {
                    e.preventDefault();
                    $(this).closest('.dropdown').removeClass('show');
                });

                // this will show navbar in left for mobile only
                $('#azNavShow, #azNavbarShow').on('click', function(e) {
                    e.preventDefault();
                    $('body').addClass('az-navbar-show');
                });

                // this will hide currently open content of page
                // only works for mobile
                $('#azContentLeftShow').on('click touch', function(e) {
                    e.preventDefault();
                    $('body').addClass('az-content-left-show');
                });

                // This will hide left content from showing up in mobile only
                $('#azContentLeftHide').on('click touch', function(e) {
                    e.preventDefault();
                    $('body').removeClass('az-content-left-show');
                });

                // this will hide content body from showing up in mobile only
                $('#azContentBodyHide').on('click touch', function(e) {
                    e.preventDefault();
                    $('body').removeClass('az-content-body-show');
                });

                // navbar backdrop for mobile only
                $('body').append('<div class="az-navbar-backdrop"></div>');
                $('.az-navbar-backdrop').on('click touchstart', function() {
                    $('body').removeClass('az-navbar-show');
                });

                // Close dropdown menu of header menu
                $(document).on('click touchstart', function(e) {
                    e.stopPropagation();

                    // closing of dropdown menu in header when clicking outside of it
                    var dropTarg = $(e.target).closest('.az-header .dropdown').length;
                    if(!dropTarg) {
                        $('.az-header .dropdown').removeClass('show');
                    }

                    // closing nav sub menu of header when clicking outside of it
                    if(window.matchMedia('(min-width: 992px)').matches) {

                        // Navbar
                        var navTarg = $(e.target).closest('.az-navbar .nav-item').length;
                        if(!navTarg) {
                            $('.az-navbar .show').removeClass('show');
                        }

                        // Header Menu
                        var menuTarg = $(e.target).closest('.az-header-menu .nav-item').length;
                        if(!menuTarg) {
                            $('.az-header-menu .show').removeClass('show');
                        }

                        if($(e.target).hasClass('az-menu-sub-mega')) {
                            $('.az-header-menu .show').removeClass('show');
                        }

                    } else {

                        //
                        if(!$(e.target).closest('#azMenuShow').length) {
                            var hm = $(e.target).closest('.az-header-menu').length;
                            if(!hm) {
                                $('body').removeClass('az-header-menu-show');
                            }
                        }
                    }
                });

                $('#azMenuShow').on('click', function(e) {
                    e.preventDefault();
                    $('body').toggleClass('az-header-menu-show');
                });

                $('.az-header-menu .with-sub').on('click', function(e) {
                    e.preventDefault();
                    $(this).parent().toggleClass('show');
                    $(this).parent().siblings().removeClass('show');
                });

                $('.az-header-menu-header .close').on('click', function(e) {
                    e.preventDefault();
                    $('body').removeClass('az-header-menu-show');
                });

                $('.nav-link:not(.with-sub)').click(function() {
                    $('.nav-item').removeClass('show');
                });
            });
        },
        // async initUser() {

        //   await this.getOrgService();
        // },
        // async getOrgService() {
        //   try {
        //     let reply = await this.$accounts.get(`/accounts/org/services`);

        //     this.org_services = reply.data ? reply.data.services : [];
        //     this.org_services.forEach(service => {
        //       switch (service.name) {
        //         case 'identity':
        //           this.identity = true;
        //           break;
        //         case 'assetregistry':
        //           this.assetregistry = true;
        //           break;
        //         case 'audits':
        //           this.audits = true;
        //           break;
        //         case 'marketplace':
        //           this.marketplace = true;
        //           break;
        //         case 'coop':
        //           this.coop = true;
        //           break;
        //         case 'admin':
        //           this.admin = true;
        //           break;
        //       }
        //     });
        //   } catch (e) {
        //     console.log(e);
        //   }
        // },
        isActive(input) {
            const paths = Array.isArray(input) ? input : [input];
            return paths.some(path => {
                if(path === '/') {
                    return this.$route.path === path;
                } else {
                    return this.$route.path.indexOf(path) !== -1;
                }
            });
        },
        isContain(servicename) {
            // console.log(servicename, _.some(this.org_services, {name: servicename}))
            return _.some(this.org_services, {name: servicename});
        },
        logout() {
            this.$store.commit("LOGOUT");
            this.$router.replace("/auth/login");
        },
        setModule(){
            if(this.$route.path.indexOf("/identities") === 0) {
                this.module = "Identity";
            } else if(this.$route.path.indexOf("/assets") === 0){
                this.module = "AssetRegistry";
            } else if(this.$route.path.indexOf("/provenance") === 0){
                this.module = "Provenance";
            }
        }
    }
}
</script>

<style scoped>
.outstanding {
    background-color: #dc3545;
    color: white;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    text-align: center;
    float: right;
    font-weight: 600;
    font-size: 9px;
    margin-right: 5px;
}
</style>