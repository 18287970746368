<template>
    <div>
        <workspace-header></workspace-header>

        <router-view></router-view>

        <workspace-footer></workspace-footer>

        <loading-screen :is-loading="isLoading"/>
    </div>

</template>

<script>
    import WorkspaceHeader from './WorkspaceHeader.vue';
    import WorkspaceFooter from './WorkspaceFooter';
    import LoadingScreen from "./LoadingScreen";
    import EventBus from "@/event-bus";

    export default {
        name: 'app',
        components: {WorkspaceHeader, WorkspaceFooter, LoadingScreen},
        data() {
            return {
                isLoading: false
            };
        },
        mounted() {
            EventBus.$on('closeLoader', () => {
                this.isLoading = false;
            });
            EventBus.$on('openLoader', () => {
                this.isLoading = true;
            });
            this.init();
        },
        methods: {
            async init() {
                $(function() {
                    'use strict';

                    if(window.$('.az-iconbar .nav-link.active').length) {
                        var targ = window.$('.az-iconbar .nav-link.active').attr('href');
                        $(targ).addClass('show');

                        if(window.matchMedia('(min-width: 1200px)').matches) {
                            $('.az-iconbar-aside').addClass('show');
                        }

                        if(window.matchMedia('(min-width: 992px)').matches &&
                                window.matchMedia('(max-width: 1199px)').matches) {
                            $('.az-iconbar .nav-link.active').removeClass('active');
                        }
                    }

                    $('.az-iconbar .nav-link').on('click', function(e) {
                        e.preventDefault();

                        $(this).addClass('active');
                        $(this).siblings().removeClass('active');

                        $('.az-iconbar-aside').addClass('show');

                        var targ = $(this).attr('href');
                        $(targ).addClass('show');
                        $(targ).siblings().removeClass('show');
                    });

                    $('.az-iconbar-body .with-sub').on('click', function(e) {
                        e.preventDefault();
                        $(this).parent().addClass('show');
                        $(this).parent().siblings().removeClass('show');
                    });

                    $('.az-iconbar-toggle-menu').on('click', function(e) {
                        e.preventDefault();

                        if(window.matchMedia('(min-width: 992px)').matches) {
                            $('.az-iconbar .nav-link.active').removeClass('active');
                            $('.az-iconbar-aside').removeClass('show');
                        } else {
                            $('body').removeClass('az-iconbar-show');
                        }
                    });

                    $('#azIconbarShow').on('click', function(e) {
                        e.preventDefault();
                        $('body').toggleClass('az-iconbar-show');
                    });


                    // Mini Bar charts
                    $('.peity-bar').peity('bar');
                });
                await Promise.all([this.getUnits(), this.getCurrencies()]);
            },
            async getUnits() {
                if(!localStorage.getItem('units')) {
                    let {data} = await this.$accounts.get('accounts/units');
                    localStorage.setItem('units', JSON.stringify(data.units));
                }
            },
            async getCurrencies() {
                if(!localStorage.getItem('currencies')) {
                    let {data} = await this.$accounts.get('accounts/currencies');
                    localStorage.setItem('currencies', JSON.stringify(data.currencies));
                }
            }
        }
    }
</script>

<style lang="scss">
</style>
